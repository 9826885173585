import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
function Navbar2() {
  let navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleClose2 = () => {
    navigate("/");
  };
  const handleShow = () => setShow(true);
  return (
    <Navbar collapseOnSelect expand="lg" fixed="top" className="navbar2">
      <Container>
        <Navbar.Brand>
          <Link to="/">Digi-Hotel</Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            <div className="mt-2">
              <Link to="/" className="link22 ">
                HOME
              </Link>

              <Button
                className="btn-cnl ms-4"
                variant="light"
                size="md"
                onClick={handleShow}
              >
                CANCEL BOOKING
              </Button>
            </div>
          </Nav>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Cancellation Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FloatingLabel
                controlId="floatingInput"
                label="Name"
                className="mb-3"
              >
                <Form.Control type="email" placeholder="name@example.com" />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingPassword"
                label="Mobile No"
                className="mb-3"
              >
                <Form.Control type="number" placeholder="Password" />
              </FloatingLabel>
              <FloatingLabel controlId="floatingPassword" label="Remarks">
                <Form.Control type="text" placeholder="Password" />
              </FloatingLabel>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-block w-100 text-center">
                <Button
                  className="btn-cnl2"
                  onClick={handleClose2}
                  variant="light"
                >
                  REQUEST
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navbar2;
