import emailjs from "@emailjs/browser";
import { useState } from "react";
import React, { useRef } from "react";

const Result = () => {
  return (
    <p className="text-success font-weight-bold">
      Your message has been sent successfully.
    </p>
  );
};
function Contact() {
  const form = useRef();
  const [result, setResult] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_4mu5a15",
        "template_ofxasbw",
        form.current,
        "sMszufQktj3aKfY_i"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    setResult(true);
  };

  setTimeout(() => {
    setResult(false);
  }, 5000);

  return (
    <section id="contact">
      <div className="container  pt-lg-5 my-3">
        <div className="row ">
          <h2 className="section-heading ">Contact Us</h2>
          <div className="col-lg-5 mb-4">
            <form ref={form} onSubmit={sendEmail}>
              <div className="contact-form">
                <div className="row gy-3">
                  <div className="col-6">
                    <input type="hidden" name="_captcha" value="false" />
                    <div className="form-group">
                      <label>Name </label>
                      <input
                        className="input-control"
                        name="name"
                        id="name"
                        type="text"
                        placeholder="Name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Phone </label>

                      <input
                        className="input-control"
                        name="phones"
                        id="phone"
                        type="tel"
                        placeholder="+91"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <label>Email </label>

                      <input
                        className="input-control"
                        name="email"
                        id="email"
                        type="email"
                        placeholder="example@mail.com"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <input
                      type="hidden"
                      name="_next"
                      value="https://Digi-Hotel.netlify.app/"
                    />
                    <div className="form-group form-group-textarea mb-md-0">
                      <label>Message</label>

                      <textarea
                        className="input-control-m"
                        name="message"
                        id="message"
                        rows="4"
                        placeholder="Message"
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <input
                    type="submit"
                    className="contact-btn"
                    id="submitButton"
                    value="SEND"
                  />
                </div>
                <div className="result d-flex align-items-center justify-content-center">
                  {result ? <Result /> : ""}
                </div>
              </div>
            </form>
          </div>

          <div className="col-lg-7">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3900.765253464488!2d78.1497857141274!3d12.128207736104839!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bac173769139bcb%3A0xa000b25c24eca9b0!2sDigiacuity%20Solutions%20(OPC)%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1671712714878!5m2!1sen!2sin"
              loading="lazy"
              className="iframe"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
