import React from "react";
import {
  GrLocation,
  GrPhone,
  GrMail,
  GrInstagram,
  GrTwitter,
  GrFacebook,
} from "react-icons/gr";
function Footer() {
  return (
    <footer className="footer">
      <div className="border-bottom p-3">
        <div className="footer-title text-center text-lg-start">Digi-Hotel</div>
      </div>

      <div className="container text-lg-start text-center mt-5">
        <div className="row">
          <div className="col-lg-4">
            <h6 className="text-uppercase fw-bold mb-4">Quick links</h6>
            <div className="d-flex flex-column mb-3">
              <p className="linkk">
                <a href="#hero" className="text-reset">
                  Home
                </a>
              </p>
              <p className="linkk">
                <a href="#about" className="text-reset">
                  About
                </a>
              </p>
              <p className="linkk">
                <a href="#room" className="text-reset">
                  Rooms
                </a>
              </p>

              <p className="linkk">
                <a href="#gallery" className="text-reset">
                  Gallery
                </a>
              </p>
            </div>
          </div>

          <div className="col-lg-4 mx-auto mb-4">
            <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
            <div className="d-flex flex-column justify-content-lg-start">
              <p className="mb-4">
                <GrLocation />
                <a
                  href="http://maps.google.com/?q=chennai"
                  target="_blank"
                  className="ms-3"
                >
                  1/121,Chennai,600028
                </a>
              </p>
              <p className="mb-4">
                <GrMail />
                <a
                  href="mailto: info@example.com"
                  target="_blank"
                  className="ms-3"
                >
                  info@example.com
                </a>
              </p>
              <p>
                <GrPhone />
                <a href="tel:+91 8428277727" className="ms-3">
                  +91 9876543210
                </a>
              </p>
            </div>
          </div>
          <div className="col-lg-4 mb-3">
            <h6 className="text-uppercase text-center fw-bold mb-4">
              Follow Us
            </h6>
            <div className="footer-icon d-flex align-items-center justify-content-center gap-4">
              <GrFacebook className="footer-icons fs-2" />

              <GrTwitter className="footer-icons fs-2" />

              <GrInstagram className="footer-icons fs-2" />
            </div>
          </div>
        </div>
      </div>

      <div className="text-center p-2">
        Copyright © 2022 All rights reserved
        <a className="text-reset ms-2 fw-bold" href="https://digiacuity.com/">
          DigiAcuity
        </a>
      </div>
    </footer>
  );
}

export default Footer;
