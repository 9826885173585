import React from "react";

function About() {
  return (
    <section id="about">
      <div className="container pt-lg-5">
        <div className="row  ">
          <h2 className="about-h1 pt-lg-3  my-4">About Us</h2>
          <div className="col-lg-6">
            <div className="check-img-holder">
              <img
                src="./images/IMG-20221010-WA0019.jpg"
                alt="check-img"
                className="check-img"
              />
            </div>
          </div>
          <div className="col-lg-6 about">
            <h2 className="about-h2 my-3">Welcome To Digi-Hotel</h2>
            <p>
              Modern interiors create a relaxing ambiance, offering guest a
              spacious and comfortable environment. relaxation and entertainment
              in the very heart of our chennai city, our Hotel is situated about
              10 kms from the airport and in the heart of Chennai's Shopping Hub
              which will offer the travelers an experience to last a lifetime.
              Our rooms have a contemporary design with a simple and functional
              decor equipped with the state of the art amenities and attentive
              staff. Our hotel is an integral part of the city and we strive to
              be the top choice from accommodation to celebrations and corporate
              events.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
