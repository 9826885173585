import React from "react";
import About from "../../components/About";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import Gallery from "../../components/Gallery";
import Navbar from "../../components/Navbar";

import Rooms from "../../components/Rooms";
import Slide from "../../components/Slide";

function Home() {
  return (
    <>
      <section id="home">
        <Navbar />
        <Slide />
      </section>
      <About />
      <Rooms />
      <Gallery />
      <Contact />
      <Footer />
    </>
  );
}

export default Home;
