import React from "react";
import { BsTv, BsWifi, BsSnow3, BsClock } from "react-icons/bs";

function Rooms() {
  const type = [
    {
      img: "images/room-single.jpg",
      name: "Single Room",
    },
    {
      img: "images/family-room.jpg",
      name: "Family Room",
    },
    {
      img: "images/pres-room.jpg",
      name: " PRESIDENTIAL Room",
    },
  ];
  return (
    <section id="rooms">
      <div className="container pt-lg-5 ">
        <div className="row">
          <h2 className="room-h1   my-4">Rooms</h2>

          {type.map((e) => {
            return (
              <>
                <div class="col-lg-4 ">
                  <div class="room-img-holder">
                    <img class="room-img" src={e.img} alt="images" />
                  </div>
                  <div class="text-center mt-3  ">
                    <h4 className="room-name">{e.name}</h4>
                    <ul className="li-card ">
                      <li>
                        <BsWifi className=" room-ic " />
                        WIFI
                      </li>
                      <li>
                        <BsSnow3 className=" room-ic " /> AC
                      </li>
                      <li>
                        <BsTv className="  room-ic" /> TV
                      </li>

                      <li>
                        <BsClock className="room-ic" />
                        24/7 SERVICE
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Rooms;
