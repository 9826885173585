import React from "react";
import BookForm from "../../components/Book/BookForm";
import Footer from "../../components/Footer";
import Navbar2 from "../../components/Hotel/Navbar2";

function Book() {
  return (
    <>
      <Navbar2 />
      <BookForm />
      <Footer />
    </>
  );
}

export default Book;
