import React, { useRef, useState } from "react";
import moment from "moment";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import Loading from "../Loading";
import { useReactToPrint } from "react-to-print";
import Print from "./Print";
import Button from "react-bootstrap/Button";
function BookForm() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [load, setload] = useState(false);
  let formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      address: "",
      note: "",
      checkin: moment(location.state.dates[0].startDate).format("DD-MM-YYYY"),
      checkout: moment(location.state.dates[0].endDate).format("DD-MM-YYYY"),
      adult: location.state.options.adult,
      child: location.state.options.children,
      room: location.state.options.room,
      total:
        location.state.options.room * location.state.bill.amount +
        location.state.options.room * location.state.bill.amount * 0.18,
    },
    onSubmit: async (values) => {
      try {
        setload(true);
        await axios.post(
          "https://erin-cloudy-penguin.cyclic.app/onlinebooking",
          values
        );

        Swal.fire({
          icon: "success",
          title: "Booking Successfully",
          showConfirmButton: false,

          timer: 1500,
        });
        setload(false);
        navigate("/");
      } catch (error) {
        alert(" online Booking Error" + error.message);
      }
    },
  });
  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <div className="container-fluid">
            <div className="row mt-4 pt-4 ">
              <form
                onSubmit={formik.handleSubmit}
                ref={componentRef}
                className="d-flex flex-wrap"
              >
                <div className="row">
                  <div class="col-lg-9 col-md-12 ">
                    <div class="card form-book mb-4 mt-4">
                      <div class="card-header py-3">
                        <h5 class="mb-0">Booking Details</h5>
                      </div>
                      <div class="card-body">
                        <div class="row  gy-2">
                          <div class="col-lg-6">
                            <div class="form-outline">
                              <label class="form-label" for="form7Example1">
                                First name
                              </label>
                              <input
                                type="text"
                                id="firstname"
                                name="firstname"
                                placeholder="First Name"
                                onChange={formik.handleChange}
                                value={formik.values.firstname}
                                class="form-control"
                                required
                              />
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-outline">
                              <label class="form-label" for="form7Example2">
                                Last name
                              </label>
                              <input
                                type="text"
                                id="lastname"
                                placeholder="Last Name"
                                name="lastname"
                                onChange={formik.handleChange}
                                value={formik.values.lastname}
                                class="form-control"
                                required
                              />
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-outline mb-2">
                              <label class="form-label" for="form7Example5">
                                Email
                              </label>
                              <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="example@mail.com"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                class="form-control"
                                required
                              />
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-outline mb-2">
                              <label class="form-label" for="form7Example6">
                                Phone
                              </label>
                              <input
                                type="number"
                                id="phone"
                                name="phone"
                                placeholder="+91"
                                onChange={formik.handleChange}
                                value={formik.values.phone}
                                class="form-control"
                                required
                              />
                            </div>
                          </div>
                          <div class="form-outline mb-2">
                            <label class="form-label" for="form7Example4">
                              Address
                            </label>

                            <textarea
                              class="form-control"
                              id="address"
                              name="address"
                              placeholder="Address"
                              onChange={formik.handleChange}
                              value={formik.values.address}
                              rows="3"
                              required
                            ></textarea>
                          </div>
                          <div class="form-outline mb-1">
                            <label class="form-label" for="form7Example7">
                              Additional information
                            </label>
                            <textarea
                              class="form-control"
                              id="note"
                              name="note"
                              onChange={formik.handleChange}
                              value={formik.values.note}
                              rows="4"
                            ></textarea>
                          </div>
                        </div>

                        {/* <div class="form-check d-flex  justify-content-center ">
                    <input type="submit" className="check-btn" value="ADD" />
                  </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3  col-md-12 my-4">
                    <div class="card form-book ">
                      <div class="card-header py-3">
                        <h5 class="mb-0">Booking Summary</h5>
                      </div>
                      <div class="card-body">
                        <div className="form-date">
                          <div>
                            <span> CheckIn</span>
                            <h5 className="mt-3">
                              {moment(location.state.dates[0].startDate).format(
                                "DD-MM-YYYY"
                              )}
                            </h5>
                          </div>
                          <div>
                            <span> CheckOut</span>
                            <h5 className="mt-3">
                              {moment(location.state.dates[0].endDate).format(
                                "DD-MM-YYYY"
                              )}
                            </h5>
                          </div>
                        </div>
                        <ul class="list-group list-group-flush ">
                          <li class="list-group-item mt-0 ">
                            Room Type
                            <span className=" fw-bold">
                              {location.state.bill.name}
                            </span>
                          </li>
                          <li class="list-group-item ">
                            No of Rooms
                            <span className="me-5 fw-bold">
                              {location.state.options.room}
                            </span>
                          </li>
                          <li class="list-group-item">
                            Guest
                            <span className="fw-bold">
                              {location.state.options.adult} Adult
                              <span className="fw-bold ms-3">
                                {location.state.options.children} Child
                              </span>
                            </span>
                          </li>
                        </ul>
                        <hr />
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item ">
                            Sub Total
                            <span className="fw-bold">
                              ₹
                              {location.state.options.room *
                                location.state.bill.amount}
                            </span>
                          </li>
                          <li class="list-group-item ">
                            Tax Fees
                            <span className="fw-bold">
                              ₹
                              {location.state.options.room *
                                location.state.bill.amount *
                                0.18}
                            </span>
                          </li>
                          <li class="list-group-item  mt-3">
                            <div>
                              <strong className="bill-total">Total</strong>
                            </div>
                            <span>
                              <strong className="text-success fs-3">
                                ₹
                                {location.state.options.room *
                                  location.state.bill.amount +
                                  location.state.options.room *
                                    location.state.bill.amount *
                                    0.18}
                              </strong>
                              <sub className="text-muted "> / Day</sub>
                            </span>
                          </li>
                        </ul>
                        <div
                          className="d-flex justify-content-center
                        mt-3 "
                        >
                          <input
                            type="submit"
                            className="book-btn  me-4"
                            value="BOOK NOW"
                          />

                          <button className="print-btn " onClick={handlePrint}>
                            Print
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default BookForm;
