import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Rooms from "./components/Rooms";
import Book from "./pages/Book/Book";

import Home from "./pages/home/Home";
import Hotel from "./pages/Hotel/Hotel";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/hotel" element={<Hotel />} />
          <Route path="/book" element={<Book />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
