import Carousel from "react-bootstrap/Carousel";
import Form from "./Form";

function Slide() {
  return (
    <Carousel fade>
      <Carousel.Item>
        <img className="caro-img " src="images/bg-3.jpg" alt="First slide" />

        <Carousel.Caption>
          <Form />
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="caro-img" src="images/bg-1.jpg" alt="Second slide" />

        <Carousel.Caption>
          <Form />
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="caro-img" src="images/bg-2.jpg" alt="Third slide" />

        <Carousel.Caption>
          <Form />
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Slide;
